import { uniqueId } from "lodash"
import { useMemo } from "react"

import { useInterestsAutoCompleteSearch } from "./useInterestsAutoCompleteSearch"
import { TopSearchDataType } from "../types"
import { FiltersType } from "@/filters/types"
import {
  FinancialAccessibility,
  LearningOpportunityType,
  Selective,
} from "@/generated/graphql"
import { SortOrder } from "@/sorting/types"

const rawTopSearchData: TopSearchDataType[] = [
  {
    name: "Browse All",
    icon: "SearchBrowse",
  },
  {
    name: "Summer",
    icon: "Summer",
    filter: {
      [FiltersType.TYPE]: [LearningOpportunityType.SummerProgram],
    },
  },
  {
    name: "School Year",
    icon: "SchoolYear",
    filter: {
      [FiltersType.TYPE]: [LearningOpportunityType.SchoolYearProgram],
    },
  },
  {
    name: "Competition",
    icon: "CompetitionsTopSearch",
    filter: {
      [FiltersType.TYPE]: [LearningOpportunityType.Competition],
    },
  },
  {
    name: "Free",
    icon: "Accessible",
    filter: {
      [FiltersType.FINANCIAL_ACCESSIBILITY]: [
        FinancialAccessibility.APlus,
        FinancialAccessibility.A,
      ],
    },
    sort: [
      {
        field: "financialAccessibility",
        order: SortOrder.Asc,
      },
    ],
  },
  {
    name: "1-on-1",
    icon: "OneOnOne",
    filter: {
      [FiltersType.INTERESTS]: [
        {
          identifier: uniqueId(),
          name: "1-on-1",
        },
      ],
    },
  },
  {
    name: "Selective",
    icon: "Selection",
    filter: {
      [FiltersType.SELECTIVE]: [Selective.Highly],
    },
  },
  {
    name: "Upcoming",
    icon: "Deadline",
    sort: [{ field: "deadline", order: SortOrder.Asc }],
  },
  {
    name: "STEM",
    icon: "STEM",
    filter: {
      [FiltersType.INTERESTS]: [
        {
          identifier: uniqueId(),
          name: "STEM",
        },
      ],
    },
  },
  {
    name: "Business",
    icon: "Business",
    filter: {
      [FiltersType.INTERESTS]: [
        {
          identifier: uniqueId(),
          name: "Business",
        },
      ],
    },
  },
  {
    name: "Engineering",
    icon: "Engineering",
    filter: {
      [FiltersType.INTERESTS]: [
        {
          identifier: uniqueId(),
          name: "Engineering",
        },
      ],
    },
  },
  {
    name: "Leadership",
    icon: "Leadership",
    filter: {
      [FiltersType.INTERESTS]: [
        {
          identifier: uniqueId(),
          name: "Leadership",
        },
      ],
    },
  },
  {
    name: "Coding",
    icon: "Programming",
    filter: {
      [FiltersType.INTERESTS]: [
        {
          identifier: uniqueId(),
          name: "Coding",
        },
        {
          identifier: uniqueId(),
          name: "Programming",
        },
      ],
    },
  },
  {
    name: "Writing",
    icon: "Write",
    filter: {
      [FiltersType.INTERESTS]: [
        {
          identifier: uniqueId(),
          name: "Writing",
        },
      ],
    },
  },
  {
    name: "Sustainability",
    icon: "Earth",
    filter: {
      [FiltersType.INTERESTS]: [
        {
          identifier: uniqueId(),
          name: "Sustainability",
        },
      ],
    },
  },
  {
    name: "Humanities",
    icon: "Community",
    filter: {
      [FiltersType.INTERESTS]: [
        {
          identifier: uniqueId(),
          name: "Humanities",
        },
      ],
    },
  },
  {
    name: "Research",
    icon: "Research",
    filter: {
      [FiltersType.INTERESTS]: [
        {
          identifier: uniqueId(),
          name: "Research",
        },
      ],
    },
  },
  {
    name: "Community Service",
    icon: "SocialCare",
    filter: {
      [FiltersType.INTERESTS]: [
        {
          identifier: uniqueId(),
          name: "Service",
        },
      ],
    },
  },
]

export const useTopSearchData = () => {
  const { interests } = useInterestsAutoCompleteSearch("")

  return useMemo(() => {
    return rawTopSearchData.map((item) => {
      if (item.filter && item.filter[FiltersType.INTERESTS]) {
        const rawFilters = Array.isArray(item.filter[FiltersType.INTERESTS])
          ? item.filter[FiltersType.INTERESTS]
          : [item.filter[FiltersType.INTERESTS]]

        const filters = rawFilters.map((interest) => {
          const matchedInterest = interests.find(
            (i) => i.name === interest.name,
          )
          if (matchedInterest) {
            return {
              ...interest,
              identifier: matchedInterest.identifier,
            }
          }

          return interest
        })

        return {
          ...item,
          filter: {
            ...item.filter,
            [FiltersType.INTERESTS]: filters,
          },
        }
      } else {
        return item
      }
    })
  }, [rawTopSearchData, interests])
}
