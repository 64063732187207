import { GtmId } from "@/config"

export function search(query: string): void {
  if (!GtmId || !window.dataLayer) {
    return
  }

  window.dataLayer = window.dataLayer || []

  const searchTermOptions = {
    event: "search",
    searchTerm: query,
  }

  window.dataLayer.push(searchTermOptions)
}
