import { useGetInterestsAutoCompleteSearchQuery } from "@/generated/graphql"
import graphqlRequestClient from "@/graphql/client"
import useDebounce from "@/ui/hooks/useDebounce"

export const useInterestsAutoCompleteSearch = (query?: string) => {
  const debouncedQuery = useDebounce(query, 300)

  const { data, isFetching } = useGetInterestsAutoCompleteSearchQuery(
    graphqlRequestClient,
    {
      query: debouncedQuery?.toLowerCase(),
    },
    {
      enabled: debouncedQuery !== "",
      keepPreviousData: true,
    },
  )

  return {
    debouncedQuery,
    isFetching,
    interests: data?.interestsAutoCompleteSearch ?? [],
  }
}
