import { Box, Container, Flex } from "@chakra-ui/react";
import { useRef, useState } from "react";
import { ArrowSlider } from "./ArrowSlider";
import { TopSearchGrid, TopSearchGridRef } from "./TopSearchGrid";
import { TopSearchDataType } from "../../types";
import SnowdayIcons from "@/icons/SnowdayIcons";
import ComponentHeading from "@/ui/common/ComponentHeading";
import { useDevice } from "@/ui/hooks";
type TopSearchViewProps = {
  data: TopSearchDataType[];
};
export default function TopSearchView({
  data
}: TopSearchViewProps) {
  const ref = useRef<TopSearchGridRef>(null);
  const [hideLeft, setHideLeft] = useState(true);
  const [hideRight, setHideRight] = useState(false);
  const scrollSection = (direction: "left" | "right") => () => {
    if (!ref.current) {
      return;
    }
    let newIndex = ref.current.getIndex();
    if (direction === "left" && newIndex > 0) {
      newIndex -= 1;
      ref.current?.scrollToPrevSection();
    } else if (direction === "right" && newIndex < ref.current?.getSectionCount() - 1) {
      newIndex += 1;
      ref.current?.scrollToNextSection();
    }
    handleHide(newIndex);
  };
  const handleHide = (index: number) => {
    if (!ref.current) {
      return;
    }
    if (index === 0) {
      setHideLeft(true);
    } else {
      setHideLeft(false);
    }
    if (index === ref.current?.getSectionCount() - 1) {
      setHideRight(true);
    } else {
      setHideRight(false);
    }
  };
  const {
    isDesktop
  } = useDevice();
  return <Box data-testid="top-search-view" mt={{
    base: 4,
    lg: 8
  }} mb={{
    base: 10
  }} pl={{
    base: 3,
    md: 0
  }} as="section" data-sentry-element="Box" data-sentry-component="TopSearchView" data-sentry-source-file="TopSearchView.tsx">
      <Container px={{
      base: 0,
      md: 20,
      lg: 0
    }} maxW={{
      md: "100%",
      lg: "720px"
    }} data-sentry-element="Container" data-sentry-source-file="TopSearchView.tsx">
        <ComponentHeading icon={<SnowdayIcons name="Snowflake" color="snow.blue" />} title="Top Searches" color="snow.blue-medium" data-sentry-element="ComponentHeading" data-sentry-source-file="TopSearchView.tsx" />
      </Container>
      <Flex justifyContent="center" data-sentry-element="Flex" data-sentry-source-file="TopSearchView.tsx">
        {isDesktop && <ArrowSlider direction="left" onClick={scrollSection("left")} hide={hideLeft} />}
        <Box px={{
        base: 0,
        md: 20,
        lg: 0
      }} maxW={{
        md: "100%",
        lg: "720px"
      }} className="no-scrollbar" overflowX={{
        base: "scroll",
        lg: "hidden"
      }} data-sentry-element="Box" data-sentry-source-file="TopSearchView.tsx">
          <TopSearchGrid data={data} ref={ref} data-sentry-element="TopSearchGrid" data-sentry-source-file="TopSearchView.tsx" />
        </Box>
        {isDesktop && <ArrowSlider direction="right" onClick={scrollSection("right")} hide={hideRight} />}
      </Flex>
    </Box>;
}