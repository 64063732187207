import { Box, Text } from "@chakra-ui/react";
import MailSnowdayButton from "../../ui/buttons/MailSnowdayButton";
export default function SearchFooter() {
  const paddingText = 5;
  const paddingBox = 5;
  return <Box p={paddingBox} w={{
    base: "100%"
  }} data-sentry-element="Box" data-sentry-component="SearchFooter" data-sentry-source-file="SearchFooter.tsx">
      <Text align="center" color="snow.blue-dark" fontWeight="bold" pt={paddingText} pb={paddingText} data-sentry-element="Text" data-sentry-source-file="SearchFooter.tsx">
        Can't find what you're looking for?
      </Text>
      <MailSnowdayButton data-sentry-element="MailSnowdayButton" data-sentry-source-file="SearchFooter.tsx" />
      <Text align="center" color="snow.blue-dark" pt={paddingText} pb={paddingText} data-sentry-element="Text" data-sentry-source-file="SearchFooter.tsx">
        Tell us what you're looking for. Our team will research and respond in
        48 hours.
      </Text>
    </Box>;
}