import { useBoolean } from "@chakra-ui/react"
import { useCallback } from "react"

export const useGoogleEvent = <T>(event: (val: T) => void) => {
  const [gtmEventFired, setGtmEventFired] = useBoolean(false)

  const fireGoogleEvent = useCallback((val: T) => {
    event(val)
    setGtmEventFired.on()
  }, [])

  return {
    gtmEventFired,
    fireGoogleEvent,
  }
}
