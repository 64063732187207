import { Box, Container, Divider, Flex } from "@chakra-ui/react";
import Head from "next/head";
import { useTranslation } from "next-i18next";
import { useEffect } from "react";
import SearchFooter from "./components/SearchFooter";
import SearchInput from "./components/SearchInput";
import SearchResults from "./components/SearchResults";
import TopSearchView from "./components/topSearch/TopSearchView";
import { SearchTypes, TopSearchDataType } from "./types";
import { useGoogleEvent } from "../google/analytics/hooks";
import { useSearch } from "@/features/search/providers/SearchProvider";
import { search } from "@/google/analytics/events";
import { useAutoCompleteSearch } from "@/search/hooks";
const SearchScreen = ({
  topSearches,
  onClick
}: {
  topSearches: TopSearchDataType[];
  onClick?: () => void;
}) => {
  const {
    t
  } = useTranslation("titles");
  const {
    query
  } = useSearch();
  const {
    isFetching,
    interests,
    providers,
    learningOpportunities
  } = useAutoCompleteSearch(query);
  const {
    fireGoogleEvent
  } = useGoogleEvent<string>(search);
  const hasResults = interests.length > 0 || providers.length > 0 || learningOpportunities.length > 0;
  const showAutoComplete = query && query.length > 0 && hasResults;

  // Send search query to GTM
  useEffect(() => {
    if (!query) {
      return;
    }
    fireGoogleEvent(query);
  }, [query]);
  return <Flex flexDirection="column" minH="100vh" data-sentry-element="Flex" data-sentry-component="SearchScreen" data-sentry-source-file="screen.tsx">
      <Head data-sentry-element="Head" data-sentry-source-file="screen.tsx">
        <title>{`Snowday | ${t("page-titles.search")}`}</title>
      </Head>
      <Box flexGrow={1} className="scrollbar" data-sentry-element="Box" data-sentry-source-file="screen.tsx">
        <Container data-sentry-element="Container" data-sentry-source-file="screen.tsx">
          <Flex py={5} flexDirection="column" alignItems="center" data-sentry-element="Flex" data-sentry-source-file="screen.tsx">
            <Box mb={5} w="100%" data-sentry-element="Box" data-sentry-source-file="screen.tsx">
              <SearchInput icon="ArrowLeft" isLoading={isFetching} onClick={onClick} data-sentry-element="SearchInput" data-sentry-source-file="screen.tsx" />
            </Box>
            {showAutoComplete && <>
                <SearchResults type={SearchTypes.INTERESTS} name="Interests" isFetching={isFetching} query={query} data={interests ?? []} />
                <Divider my={3} borderWidth="2px" borderRadius="2px" borderColor="rgba(36, 47, 80, 0.12)" />
                <SearchResults type={SearchTypes.LEARNING_OPPORTUNITIES} name="Learning Opportunities" isFetching={isFetching} query={query} data={learningOpportunities ?? []} />
                <Divider my={3} borderWidth="2px" borderRadius="2px" borderColor="rgba(36, 47, 80, 0.12)" />
                <SearchResults type={SearchTypes.PROVIDERS} name="Providers" isFetching={isFetching} query={query} data={providers ?? []} />
                <Divider my={3} borderWidth="2px" borderRadius="2px" borderColor="rgba(36, 47, 80, 0.12)" />
                <SearchFooter />
              </>}
          </Flex>
        </Container>
        {!showAutoComplete && <TopSearchView data={topSearches} />}
      </Box>
    </Flex>;
};
export default SearchScreen;