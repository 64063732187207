import graphqlRequestClient from "../../graphql/client"
import { useGetAutoCompleteSearchQuery } from "@/generated/graphql"

export const useAutoCompleteSearch = (query?: string) => {
  const { data, isFetching } = useGetAutoCompleteSearchQuery(
    graphqlRequestClient,
    {
      query: query?.toLowerCase(),
    },
    {
      enabled: query !== "",
      keepPreviousData: true,
    },
  )

  return {
    isFetching,
    interests: data?.autoCompleteSearch.interests ?? [],
    providers: data?.autoCompleteSearch.providers ?? [],
    learningOpportunities: data?.autoCompleteSearch.learningOpportunities ?? [],
  }
}
