import { Box, Grid } from "@chakra-ui/react";
import { MutableRefObject } from "react";
import { useDevice } from "@/ui/hooks";
export interface TopSearchSectionProps {
  sectionsRef: MutableRefObject<HTMLDivElement[]>;
  index: number;
  section: JSX.Element[];
}
export const TopSearchSection = ({
  sectionsRef,
  index,
  section
}: TopSearchSectionProps) => {
  const {
    isMobile
  } = useDevice();
  const numSections = () => {
    if (isMobile) {
      return Math.ceil(section.length / 2);
    }
    return 5;
  };
  return <Box as="section" ref={(el: HTMLDivElement) => sectionsRef.current[index] = el} px={{
    base: 1,
    md: 2
  }} data-sentry-element="Box" data-sentry-component="TopSearchSection" data-sentry-source-file="TopSearchSection.tsx">
      <Grid justifyContent="normal" data-testid={`top-search-grid-section-{index}`} gap={{
      base: 2,
      md: 4
    }} templateColumns={{
      base: `repeat(${numSections()}, 100px)`,
      md: `repeat(${numSections()}, 120px)`
    }} data-sentry-element="Grid" data-sentry-source-file="TopSearchSection.tsx">
        {section.map(item => item)}
      </Grid>
    </Box>;
};