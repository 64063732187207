import { Box, Flex, Heading, TextProps } from "@chakra-ui/react";
interface ComponentHeadingProps extends TextProps {
  title: string;
  subtitle?: string;
  icon: React.ReactNode;
}
export default function ComponentHeading(props: ComponentHeadingProps) {
  return <Flex alignItems="flex-start" gap={2} pb={{
    base: 3
  }} data-sentry-element="Flex" data-sentry-component="ComponentHeading" data-sentry-source-file="ComponentHeading.tsx">
      <Box mt={{
      lg: 0.5
    }} data-sentry-element="Box" data-sentry-source-file="ComponentHeading.tsx">{props.icon}</Box>
      <Box data-sentry-element="Box" data-sentry-source-file="ComponentHeading.tsx">
        <Heading variant="h2" {...props} data-sentry-element="Heading" data-sentry-source-file="ComponentHeading.tsx">
          {props.title}
        </Heading>
        <Heading variant="h3" {...props} fontWeight={400} data-sentry-element="Heading" data-sentry-source-file="ComponentHeading.tsx">
          {props.subtitle}
        </Heading>
      </Box>
    </Flex>;
}