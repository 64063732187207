import { Center, IconButton, Input, InputGroup, InputLeftElement, InputRightElement, Spinner } from "@chakra-ui/react";
import { useRouter } from "next/router";
import { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from "react";
import { useSearch } from "@/features/search/providers/SearchProvider";
import SnowdayIcons, { iconTypes } from "@/icons/SnowdayIcons";
import useDebounce from "@/ui/hooks/useDebounce";
import SearchIcon from "@/ui/icons/Search";
interface SearchInputProps {
  onChange?: (v: string) => void;
  onClick?: () => void;
  goBack?: boolean;
  icon?: keyof typeof iconTypes;
  isLoading?: boolean;
}
export default function SearchInput({
  onClick,
  goBack = true,
  icon = "Search",
  isLoading = false
}: SearchInputProps) {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const {
    back,
    push
  } = useRouter();
  const {
    query: savedQuery,
    setQuery: setSavedQuery
  } = useSearch();
  const [currentQuery, setCurrentQuery] = useState(savedQuery);
  const debouncedQuery = useDebounce(currentQuery, 300);
  useEffect(() => {
    setSavedQuery(debouncedQuery);
  }, [debouncedQuery]);
  useEffect(() => {
    if (inputRef.current && goBack) {
      inputRef.current.focus();
    }
  }, [inputRef.current, goBack]);
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      value
    } = event.target;
    setCurrentQuery(value);
  };
  const handleKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };
  const handleSearch = () => {
    push(`/search/results?query=${currentQuery}`);
  };
  const renderSpinner = () => {
    if (!isLoading) {
      return;
    }
    return <Center data-sentry-element="Center" data-sentry-component="renderSpinner" data-sentry-source-file="SearchInput.tsx">
        <Spinner data-testid="search-input-loading" size="sm" color="snow.blue" data-sentry-element="Spinner" data-sentry-source-file="SearchInput.tsx" />
      </Center>;
  };
  const renderInputRightChildren = () => {
    return <>
        {renderSpinner()}
        <Center marginLeft={3} data-sentry-element="Center" data-sentry-source-file="SearchInput.tsx">
          <IconButton isRound={true} size={{
          base: "xs",
          lg: "md"
        }} aria-label="Search" width={{
          base: "var(--chakra-sizes-6)",
          lg: "var(--chakra-sizes-10)"
        }} icon={<SearchIcon width={{
          base: "16px",
          lg: "24px"
        }} height={{
          base: "16px",
          lg: "24px"
        }} />} isDisabled={!currentQuery || currentQuery.trim() === ""} onClick={handleSearch} data-sentry-element="IconButton" data-sentry-source-file="SearchInput.tsx" />
        </Center>
      </>;
  };
  return <InputGroup data-testid="search-input" pointerEvents={!goBack ? "none" : "all"} alignItems="center" flexDirection="row" data-sentry-element="InputGroup" data-sentry-component="SearchInput" data-sentry-source-file="SearchInput.tsx">
      <InputLeftElement cursor="pointer" children={<SnowdayIcons name={icon} color="snow.blue-medium" />} onClick={onClick ? onClick : goBack ? back : () => null} top={{
      lg: "8px"
    }} data-sentry-element="InputLeftElement" data-sentry-source-file="SearchInput.tsx" />
      <Input data-testid="search-input-input" ref={inputRef} placeholder="Search by interest, name..." _placeholder={{
      opacity: 1,
      color: "gray.500",
      fontStyle: "italic"
    }} backgroundColor="white" height={{
      base: "40px",
      lg: "56px"
    }} py={{
      lg: 5
    }} value={currentQuery ?? ""} onChange={handleChange} onKeyUp={handleKeyUp} data-sentry-element="Input" data-sentry-source-file="SearchInput.tsx" />
      <InputRightElement justifyContent="right" width={100} paddingRight={3} top={{
      lg: "8px"
    }} children={renderInputRightChildren()} data-sentry-element="InputRightElement" data-sentry-source-file="SearchInput.tsx" />
    </InputGroup>;
}