import { Card, Flex, GridItem, Text, VStack } from "@chakra-ui/react";
import { useRouter } from "next/router";
import { useCallback } from "react";
import { FilterState } from "@/filters/state/reducer";
import SnowdayIcons, { iconTypes } from "@/icons/SnowdayIcons";
import { SortOrdering } from "@/sorting/types";
import { stringifySortOrderings } from "@/sorting/utils/stringifySortOrderings";
interface TopSearchItemProps {
  name: string;
  icon: keyof typeof iconTypes;
  filter?: FilterState;
  sort?: SortOrdering[];
  gridRow?: number;
}
export default function TopSearchItem({
  name,
  icon,
  filter,
  sort,
  gridRow
}: TopSearchItemProps) {
  const router = useRouter();
  const handleClick = useCallback(() => {
    const params = [];
    if (filter) {
      params.push(["filter", JSON.stringify(filter)]);
    }
    if (sort) {
      params.push(["sort", stringifySortOrderings(sort)]);
    }
    if (params.length > 0) {
      router.push(`/search/results?${new URLSearchParams(params)}`);
    } else {
      router.push(`/search/results`);
    }
  }, [filter]);
  return <GridItem as={Card} data-testid={`top-search-item`} cursor="pointer" boxSize={{
    base: "100px",
    md: "120px"
  }} boxShadow="0px 1px 2px rgba(52, 67, 114, 0.08), 0px 2px 4px rgba(52, 67, 114, 0.2)" onClick={handleClick} gridRow={gridRow} data-sentry-element="GridItem" data-sentry-component="TopSearchItem" data-sentry-source-file="TopSearchItem.tsx">
      <Flex height="100%" justifyContent="center" data-sentry-element="Flex" data-sentry-source-file="TopSearchItem.tsx">
        <VStack justifyContent="center" data-sentry-element="VStack" data-sentry-source-file="TopSearchItem.tsx">
          <SnowdayIcons boxSize={{
          base: "32px",
          md: "44px"
        }} name={icon} data-sentry-element="SnowdayIcons" data-sentry-source-file="TopSearchItem.tsx" />
          <Text align="center" fontSize={{
          base: 14,
          md: 16
        }} color="snow.blue-dark" fontWeight={700} data-sentry-element="Text" data-sentry-source-file="TopSearchItem.tsx">
            {name}
          </Text>
        </VStack>
      </Flex>
    </GridItem>;
}