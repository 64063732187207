import { Link, Text } from "@chakra-ui/react";
import EmailIcon from "@/icons/Email";
export default function MailSnowdayButton() {
  const body = encodeURI(`Hi Snowday Response Team:

I wasn't able to find what I was looking for and would appreciate your help researching opportunities. I'm looking for....  [write here]
[describe what you're looking for with as much detail as possible, including interest, dates of availability, location (if in-person), student age/pre-reqs and budget.]
[Here are two example help requests:  I'm looking for...
1) Highly selectiv programs teaching beginner Mandarin. My son has availability during the month of August (between Aug 5 and Aug 25) and we prefer it to be in-person, day program in New York City. Flexible on cost.
2) Any good, in-person (preferably Brooklyn, NY) or online course teaching Bio-Chem. Student is rising 11th grader who loved AP Bio and AP Chem over the last year 2 years. Availability: June and July. Low cost preferred.]

`);
  const handleClick = () => {
    window.location.href = `mailto:ask@snow.day?subject=Ask Snowday Request&Content-Type=text/html&body=${body}`;
  };
  return <Link data-testid="ask-snowday-button" display="flex" flexDirection="row" alignItems="center" justifyContent="center" color="snow.blue" fontWeight={700} onClick={handleClick} data-sentry-element="Link" data-sentry-component="MailSnowdayButton" data-sentry-source-file="MailSnowdayButton.tsx">
      <EmailIcon mr={2} data-sentry-element="EmailIcon" data-sentry-source-file="MailSnowdayButton.tsx" />
      <Text data-sentry-element="Text" data-sentry-source-file="MailSnowdayButton.tsx">Ask Snowday</Text>
    </Link>;
}