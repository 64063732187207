import { GtmId } from "@/config"

export function textCollapsed(): void {
  if (!GtmId || !window.dataLayer) {
    return
  }

  window.dataLayer = window.dataLayer || []

  const textCollapsedOptions = {
    event: "textCollapsed",
  }

  window.dataLayer.push(textCollapsedOptions)
}
